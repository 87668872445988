<template>
  <LineChartWithDateRange
    title="Fee volume per product"
    :config="productLineChart"
    :options="productsFees"
    :action="fetchProductsFees"
    :series-reducer="productsFeesReducer"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LineChartWithDateRange from '@/views/apps/analytics-dashboard/charts/LineChartWithDateRange.vue'
import { formattedShortDate } from '@/tools/util'
import productLineChart from '@/views/apps/analytics-dashboard/config/productLineChart'

export default {
  name: 'ProductsFeesChart',
  components: { LineChartWithDateRange },
  computed: {
    ...mapGetters({
      productsFees: 'dashboard/productsFees',
      productsFeesTypes: 'dashboard/productsFeesTypes',
    }),
    productLineChart() {
      return productLineChart
    },
  },
  methods: {
    ...mapActions({
      fetchProductsFees: 'dashboard/fetchProductsFees',
    }),
    formattedShortDate,
    productsFeesReducer(acc, item) {
      const categories = [...acc.categories, this.formattedShortDate(item.date)]

      const startArr = ['swap', 'p2p', 'buy/sell'].map(label => ({ data: [], name: label }))

      const series = startArr.map((seriesInst, index) => {
        const fee = item.products.find(product => {
          if (seriesInst.name === 'buy/sell') {
            return product.product === 'exchange'
          }
          return seriesInst.name === product.product
        })?.fee || 0
        return { name: seriesInst.name, data: [...(acc.series?.[index]?.data || []), fee] }
      })

      return { series, categories }
    },
  },
}
</script>

<style scoped lang="scss"></style>
