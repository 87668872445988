<template>
  <ComboChartWithDateRange
    title="Total users and active users"
    :options="comboCharts.activeUsers"
    :action="fetchActiveUsers"
    :config="comboLineColumnChart"
    :series-reducer="activeUsersReducer"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ComboChartWithDateRange from '@/views/apps/analytics-dashboard/charts/ComboChartWithDateRange.vue'
import comboLineColumnChart from '@/views/apps/analytics-dashboard/config/comboLineColumnChart'
import { formattedShortDate } from '@/tools/util'

export default {
  name: 'UsersComboChart',
  components: { ComboChartWithDateRange },
  computed: {
    comboLineColumnChart() {
      return comboLineColumnChart
    },
    ...mapGetters({
      comboCharts: 'dashboard/comboCharts',
    }),
  },
  methods: {
    ...mapActions({
      fetchActiveUsers: 'dashboard/fetchActiveUsers',
    }),
    formattedShortDate,
    activeUsersReducer(acc, item) {
      const categories = [...acc.categories, this.formattedShortDate(item.date)]
      const series = [

        { name: 'Active', data: [...(acc.series?.[0]?.data || []), item.active], tooltip: '' },
        {
          name: 'Total', data: [...(acc.series?.[1]?.data || []), item.total], type: 'column',
        },
      ]

      return { series, categories }
    },
  },
}
</script>

<style scoped lang="scss"></style>
