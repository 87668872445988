<template>
  <ApexDonutChart
    v-if="hasData"
    title="DeFi Wallets"
    :options="walletsTypesChart.options"
    :series="walletsTypesChart.series"
  />
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import waitRequest from '@/mixins/waitRequest'
import ApexDonutChart from '@/views/apps/analytics-dashboard/charts/ApexDonutChart.vue'
import donatChart from '@/views/apps/analytics-dashboard/config/donatChart'

export default {
  name: 'DeFiWalletsPieChart',
  components: { ApexDonutChart },
  mixins: [waitRequest],
  computed: {
    ...mapGetters({
      deFiWalletsTypes: 'dashboard/deFiWalletsTypes',
    }),

    walletsTypesChart() {
      return this.toWalletsTypesDonutChart(this.deFiWalletsTypes || {})
    },
    hasData() {
      if (!this.deFiWalletsTypes) return false
      return !!Object.keys(this.deFiWalletsTypes).length
    },
  },

  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchDeFiWalletsTypes: 'dashboard/fetchDeFiWalletsTypes',
    }),
    initState() {
      this.fetchDeFiWalletsTypes().catch(this.checkErrors)
    },

    toWalletsTypesDonutChart(walletsTypes) {
      const wallets = Object.entries(walletsTypes)

      const { labels, series } = wallets?.reduce(
        (acc, [, wallet]) => ({
          series: [...acc.series, wallet.percentage],
          labels: [...acc.labels, wallet.type],
        }),
        { series: [], labels: [] },
      )

      // if (!labels.length) {
      //   labels = ['No data']
      //   series = [0]
      // }

      const options = {
        ...donatChart.chartOptions,
        labels,
      }

      return {
        options,
        series,
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
