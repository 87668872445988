<template>
  <b-card :title="title">
    <b-card-body>
      <statistic-card-with-area-chart
        v-for="infoItem in infoItems"
        :key="infoItem.title"
        :icon="infoItem.icon"
        :color="infoItem.color"
        :statistic-title="infoItem.title"
        :statistic="infoItem.statistic"
      />
    </b-card-body>
  </b-card>
</template>
<script>
import { BCard, BCardBody } from 'bootstrap-vue'
import StatisticCardWithAreaChart from '@/views/apps/analytics-dashboard/charts/StatisticCardWithAreaChart.vue'

export default {
  name: 'InfoWidget',
  components: {
    StatisticCardWithAreaChart,
    BCard,
    BCardBody,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    infoItems: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
