<template>
  <ApexComboLineColumnChart
    :title="title"
    :series="comboChartData.series"
    :options="comboChartData.options"
  >
    <template #filters>
      <DateRangeSearch @search="onSearch" />
    </template>
  </ApexComboLineColumnChart>
</template>

<script>
import ApexComboLineColumnChart from '@/views/apps/analytics-dashboard/charts/ApexComboLineColumnChart.vue'
import DateRangeSearch from '@/views/apps/analytics-dashboard/components/DateRangeSearch.vue'
import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

export default {
  name: 'ComboChartWithDateRange',
  components: { DateRangeSearch, ApexComboLineColumnChart },
  mixins: [waitRequest, validationError],
  props: {
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    action: {
      type: Function,
      required: true,
    },
    seriesReducer: {
      type: Function,
      required: true,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dateTo: '',
      dateFrom: '',
    }
  },

  computed: {
    comboChartOptions() {
      return this.options || {}
    },
    comboChartData() {
      return this.toComboChart(this.comboChartOptions, this.seriesReducer)
    },
  },
  mounted() {
    this.initState()
  },

  methods: {
    onSearch({ dateFrom, dateTo }) {
      this.dateFrom = dateFrom
      this.dateTo = dateTo

      const query = this.createQueryConfig()

      this.fetchChartData(query)
    },
    initState() {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() - 30)

      this.dateTo = new Date().toISOString().slice(0, 10)
      this.dateFrom = currentDate.toISOString().slice(0, 10)

      const query = this.createQueryConfig()
      this.fetchChartData(query)
    },
    createQueryConfig(config) {
      return {
        from: this.dateFrom,
        to: this.dateTo,

        ...config,
      }
    },

    fetchChartData(query) {
      return this.waitRequest(() => this.action(query).catch(this.checkErrors))
    },

    toComboChart(range, reducer) {
      const { categories = [], series = [] } = range?.reduce(reducer, { series: [], categories: [] })

      const options = {
        ...this.config.chartOptions,
        xaxis: { categories },
      }

      return {
        options,
        series,
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
