import chartColors from '@/views/apps/analytics-dashboard/config/colors'
import { nFormat } from '@/tools/util'

export default {
  series: [
    {
      name: '',
      data: [200, 880, 1200],
    },
  ],
  chartOptions: {
    chart: {
      type: 'bar',
      height: 450,
    },
    xaxis: {
      categories: ['Level 3', 'Level 2', 'Level 1'],
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        distributed: true,
        barHeight: '80%',
        isFunnel: true,
      },
    },
    colors: [
      chartColors.series7,
      chartColors.series6,
      chartColors.series4,
    ],
    dataLabels: {
      enabled: true,
      formatter(val) {
        return nFormat(val)
      },
      dropShadow: {
        enabled: true,
      },
    },
    title: {
      align: 'middle',
    },
    legend: {
      show: false,
    },
    tooltip: {
      y: {
        formatter(val) {
          return nFormat(val)
        },
      },
    },
  },
}
