<template>
  <b-card>
    <b-card-title class="mb-1" v-if="title">
      {{ title }}
    </b-card-title>
    <b-card-sub-title class="mb-2" v-if="subTitle">
      {{ subTitle }}
    </b-card-sub-title>

    <vue-apex-charts type="bar" :height="options.chart.height" :options="options" :series="series" />
  </b-card>
</template>

<script>
import { BCard, BCardTitle, BCardSubTitle } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
  },
}
</script>
