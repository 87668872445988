<template>
  <GChart
    ref="gCharts"
    :type="type"
    :data="chartColumns"
    :options="options"
    :settings="settings"
  />
</template>

<script>
import { GChart } from 'vue-google-charts/legacy'

export const chartType = 'GeoChart'

export const chartOptions = {
  displayMode: 'regions',
}

export default {
  name: 'GoogleChart',
  components: {
    GChart,
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
    header: {
      type: Array,
      default: () => ['Country', 'Volume ($)'],
    },
  },
  data() {
    return {
      type: chartType,
      options: chartOptions,
      settings: {
        packages: ['geochart'],
        mapsApiKey: 'AIzaSyCFiMHVsG3tOd4oNqIzEuxrQs-TOwyvnGU',
      },
    }
  },
  computed: {
    chartColumns() {
      return [this.header, ...this.rows]
    },
  },
}
</script>
