import { nFormat } from '@/tools/util'
import chartColors from '@/views/apps/analytics-dashboard/config/colors'

export default {
  series: [
    {
      name: '',
      data: [200, 880],
    },
  ],
  chartOptions: {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
    },
    plotOptions: {
      bar: {
        style: {
          colors: ['#fff'],
        },
      },
    },

    tooltip: {
      y: {
        formatter(val) {
          // eslint-disable-next-line radix
          return `${nFormat(parseInt(val))}`
        },
      },
    },
    colors: [chartColors.series5, chartColors.series6, chartColors.series7],
    dataLabels: {
      enabled: true,

      formatter: val => nFormat(val),
      dropShadow: {
        enabled: true,
      },
      style: {
        colors: ['#ffffff'],
      },
    },
    title: {
      // text: 'Pyramid Chart',
      align: 'middle',
    },
    xaxis: {
      categories: ['post fees', 'before fees'],
    },
    yaxis: {
      labels: {
        formatter: val => nFormat(val),
      },
    },
    legend: {
      show: false,
      position: 'right',
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  },
}
