import { render, staticRenderFns } from "./DeFiWalletsPieChart.vue?vue&type=template&id=7a3243a6&scoped=true&"
import script from "./DeFiWalletsPieChart.vue?vue&type=script&lang=js&"
export * from "./DeFiWalletsPieChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a3243a6",
  null
  
)

export default component.exports