<template>
  <b-card>
    <b-card-title
      v-if="title"
      class="mb-1"
    >
      {{ title }}
    </b-card-title>
    <b-card-sub-title
      v-if="subTitle"
      class="mb-2"
    >
      {{ subTitle }}
    </b-card-sub-title>
    <b-card-body>
      <div class="mx-1">
        <slot name="filters" />
      </div>
      <vue-apex-charts
        :height="options.chart.height"
        :options="options"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardSubTitle, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCardBody,
    BCard,
    BCardSubTitle,
    BCardTitle,
    VueApexCharts,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss"></style>
