import { nFormat } from '@/tools/util'
import chartColors from './colors'

export default {
  series: [85, 16, 50, 50],
  chartOptions: {
    chart: {
      height: 350,
    },

    labels: ['Product 1', 'Product 2', 'Product 3 ', 'Product 4'],

    tooltip: {
      y: {
        formatter(val) {
          // eslint-disable-next-line radix
          return `${nFormat(parseInt(val))}`
        },
      },
    },

    colors: [
      chartColors.series1,
      chartColors.series2,
      chartColors.series3,
      chartColors.series4,
      chartColors.series5,
      chartColors.series6,
      chartColors.series7,
    ],

    legend: {
      show: true,
      position: 'bottom',
      fontSize: '14px',
      fontFamily: 'Montserrat',
      height: 50,
    },

    dataLabels: {
      enabled: true,
      formatter(val) {
        return nFormat(val)
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: '2rem',
              fontFamily: 'Montserrat',
            },
            value: {
              fontSize: '1rem',
              fontFamily: 'Montserrat',
              formatter(val) {
                // eslint-disable-next-line radix
                return `${nFormat(parseInt(val))}`
              },
            },
            total: {
              show: true,
              fontSize: '1.5rem',
              label: 'Total',
              formatter(val) {
                return nFormat(val.config?.series?.reduce((acc, item) => acc + item, 0) || 0)
              },
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 320,
          },
          legend: {
            position: 'bottom',
            height: 40,
            fontSize: '12px',
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 250,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '1.5rem',
                  },
                  value: {
                    fontSize: '1rem',
                  },
                  total: {
                    fontSize: '1.5rem',
                  },
                },
              },
            },
          },
          legend: {
            show: true,
          },
        },
      },
    ],
  },
}
