<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-1" v-if="title">
        {{ title }}
      </b-card-title>
      <b-card-sub-title class="mb-2" v-if="subTitle">
        {{ subTitle }}
      </b-card-sub-title>
    </b-card-header>

    <b-card-body>
      <div class="mx-1">
        <slot name="filters" />
      </div>

      <vue-apex-charts type="line" :height="options.chart.height" :options="options" :series="series" />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
  },
}
</script>
