<template>
  <b-row class="align-items-center">
    <b-col
      md="4"
      xl="4"
      class="mb-1"
    >
      <b-form-group label="Start date">
        <b-form-datepicker
          v-model="dateFrom"
          placeholder="Choose a min date"
          local="en"
          today-button
          reset-button
          close-button
        />
      </b-form-group>
    </b-col>

    <b-col
      md="4"
      xl="4"
      class="mb-1"
    >
      <b-form-group label="End date">
        <b-form-datepicker
          v-model="dateTo"
          placeholder="Choose a max date"
          local="en"
          today-button
          reset-button
          close-button
        />
      </b-form-group>
    </b-col>

    <b-col
      md="1"
      xl="1"
      class="mb-1 mt-auto pb-1"
    >
      <b-button
        :disabled="requestInProgress"
        class="mt-auto"
        type="button"
        variant="primary"
        @click="search"
      >
        <feather-icon
          icon="SearchIcon"
          size="18"
        />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BCol, BFormDatepicker, BFormGroup, BRow,
} from 'bootstrap-vue'

export default {
  name: 'DateRangeSearch',
  components: {
    BCol,
    BFormGroup,
    BRow,
    BButton,
    BFormDatepicker,
  },
  props: {
    requestInProgress: {
      type: Boolean,
      default: false,
    },
    toValue: {
      type: String,
      default: '',
    },
    fromValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dateFrom: '',
      dateTo: '',
    }
  },
  emits: ['search'],
  mounted() {
    this.initDate()
  },
  methods: {
    search() {
      this.$emit('search', {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      })
    },
    initDate() {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() - 30)

      this.dateTo = new Date().toISOString().slice(0, 10)
      this.dateFrom = currentDate.toISOString().slice(0, 10)
    },
  },
}
</script>

<style scoped lang="scss"></style>
