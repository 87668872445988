import { $themeColors } from '@themeConfig'
import chartColors from '@/views/apps/analytics-dashboard/config/colors'
import { nFormat } from '@/tools/util'

export default {
  series: [
    {
      data: [280, 200, 220, 180, 270, 250, 70, 90, 200, 150, 160, 100, 150, 100, 50],
      type: 'column',
    },
    {
      data: [9, 12, 24, 23, 10, 15, 8, 10, 15, 10, 12, 8, 10, 8, 5],
      type: 'line',
    },
  ],
  chartOptions: {
    colors: [chartColors.series5, chartColors.series4],
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      height: 350,
    },
    markers: {
      strokeWidth: 7,
      strokeOpacity: 1,
      strokeColors: [$themeColors.light],
      colors: [$themeColors.warning],
    },
    dataLabels: {
      enabled: false,
      formatter: val => nFormat(val),
    },
    stroke: {
      curve: 'straight',
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    // tooltip: {
    //   custom(data) {
    //     const indexValue = data.series?.[data.seriesIndex]?.[data.dataPointIndex] || 0
    //     const name = data.ctx?.series?.w?.config?.series?.[data.seriesIndex]?.tooltip || ''
    //
    //     return `${'<div class="px-1 py-50"><span>'}${nFormat(indexValue, 'en', 2)} ${name}</span></div>`
    //   },
    // },
    xaxis: {
      categories: [
        '7/12',
        '8/12',
        '9/12',
        '10/12',
        '11/12',
        '12/12',
        '13/12',
        '14/12',
        '15/12',
        '16/12',
        '17/12',
        '18/12',
        '19/12',
        '20/12',
        '21/12',
      ],
    },
    yaxis: [{
      labels: {
        formatter: val => nFormat(val, 'en', 2),
      },
    },
    {
      labels: {
        formatter: val => nFormat(val, 'en', 2),
      },
      opposite: true,
    }],
  },
}
