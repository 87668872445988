<template>
  <b-card :title="title">
    <b-card-body>
      <div class="hold-info">
        <div class="truncate">
          <b-avatar class="mb-1" variant="light-primary" size="45">
            <feather-icon size="21" icon="UsersIcon" />
          </b-avatar>
          <h2 class="mb-25 font-weight-bolder">
            {{ nFormat(infoItems.totalUsers || 0) }}
          </h2>

          <span>SHOLD users</span>
        </div>
        <div class="truncate">
          <b-avatar class="mb-1" variant="light-primary" size="45">
            <feather-icon size="21" icon="GlobeIcon" />
          </b-avatar>
          <h2 class="mb-25 font-weight-bolder">
            {{ nFormat(infoItems.totalFarmedAmount || 0) }}
          </h2>

          <span>Farmed HP</span>
        </div>
        <div class="truncate">
          <b-avatar class="mb-1" variant="light-primary" size="45">
            <feather-icon size="21" icon="UserPlusIcon" />
          </b-avatar>
          <h2 class="mb-25 font-weight-bolder">
            {{ nFormat(infoItems.totalReferralUsers || 0) }}
          </h2>

          <span>referral users</span>
        </div>
        <div class="truncate">
          <b-avatar class="mb-1" variant="light-primary" size="45">
            <feather-icon size="21" icon="ShoppingCartIcon" />
          </b-avatar>
          <h2 class="mb-25 font-weight-bolder">
            {{ nFormat(infoItems.totalLinksBought || 0) }}
          </h2>

          <span>links bought</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>

import { BCard, BCardBody, BAvatar } from 'bootstrap-vue'
import { nFormat } from '@/tools/util'

export default {
  name: 'InfoHoldWidget',
  components: {
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    infoItems: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    nFormat,
  },
}
</script>

<style lang="scss" scoped>
.hold-info {
  display: flex;
  flex-wrap: wrap;
  row-gap: 2rem;
  .truncate {
    width: 50%;
    padding-top: 1.5rem;
  }
}
</style>
