<template>
  <ApexLineChart
    :title="title"
    :options="LineChartData.options"
    :series="LineChartData.series"
  >
    <template #filters>
      <b-row>
        <b-col
          md="4"
          xl="4"
          class="mb-1"
        >
          <b-form-group label="Start date">
            <b-form-datepicker
              v-model="dateFrom"
              placeholder="Choose a min date"
              local="en"
              today-button
              reset-button
              close-button
            />
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
          class="mb-1"
        >
          <b-form-group label="End date">
            <b-form-datepicker
              v-model="dateTo"
              placeholder="Choose a max date"
              local="en"
              today-button
              reset-button
              close-button
            />
          </b-form-group>
        </b-col>

        <b-col
          md="1"
          xl="1"
          class="mb-1 mt-auto pb-1"
        >
          <b-button
            :disabled="requestInProgress"
            class="mt-auto"
            type="button"
            variant="primary"
            @click="search"
          >
            <feather-icon
              icon="SearchIcon"
              size="18"
            />
          </b-button>
        </b-col>
      </b-row>
    </template>
  </ApexLineChart>
</template>
<script>
import {
  BButton, BCol, BFormDatepicker, BFormGroup, BRow,
} from 'bootstrap-vue'
import ApexLineChart from '@/views/apps/analytics-dashboard/charts/ApexLineChart.vue'
import LineChart from '@/views/apps/analytics-dashboard/config/lineChart'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

export default {
  name: 'LineChartWithDateRange',
  components: {
    BFormDatepicker, BRow, BCol, BFormGroup, BButton, ApexLineChart,
  },
  mixins: [waitRequest, validationError],
  props: {
    title: {
      type: String,
      default: '',
    },

    options: {
      type: Array,
      default: () => [],
    },

    action: {
      type: Function,
      required: true,
    },

    seriesReducer: {
      type: Function,
      required: true,
    },

    config: {
      type: Object,
      default: () => LineChart,
    },
    seriesNormalize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateTo: '',
      dateFrom: '',
    }
  },

  computed: {
    linChartOptions() {
      return this.options || []
    },

    LineChartData() {
      return this.toLineChart(this.linChartOptions, this.seriesReducer)
    },
  },

  mounted() {
    this.initState()
  },

  methods: {
    initState() {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() - 30)

      this.dateTo = new Date().toISOString().slice(0, 10)
      this.dateFrom = currentDate.toISOString().slice(0, 10)

      const query = this.createQueryConfig()

      this.fetchChartData(query)
    },

    search() {
      const query = this.createQueryConfig()

      return this.fetchChartData(query)
    },

    createQueryConfig(config) {
      return {
        dateFrom: this.dateFrom || undefined,
        dateTo: this.dateTo || undefined,

        ...config,
      }
    },

    fetchChartData(query) {
      return this.waitRequest(() => this.action(query).catch(this.checkErrors))
    },

    toLineChart(range, reducer) {
      let { categories = [], series = [] } = range?.reduce(reducer, { series: [], categories: [] })

      const options = {
        ...this.config.chartOptions,
        xaxis: { categories },
      }

      if (this.seriesNormalize) {
        series = series.filter(serie => serie.data.some(value => value !== 0))
      }

      return {
        options,
        series,
      }
    },
  },
}
</script>
